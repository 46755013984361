<template>
	<div>
		<div class="box box_mm">
			<div class="box_main">
				<div
					style="height: 70vh;overflow-y: auto;padding-right: 0.5rem;padding-bottom: 40px;box-sizing: border-box;">
					<el-form :model="addnewinfo" :rules="addnewinfo_rules" ref="addnewinfo" label-width="100px"
						label-position="left" class="demo-ruleForm" style="padding-left: 20px;">
						<el-form-item label="标题" prop="name">
							<el-input v-model="addnewinfo.name" style="width: 60%;" placeholder="标题"></el-input>
						</el-form-item>
						<el-form-item label="内容" prop="content" required>
							<el-input
							  type="textarea" style="width: 60%;"
							  :rows="4"
							  placeholder="请输入内容"
							  v-model="addnewinfo.content">
							</el-input>
						</el-form-item>
						<el-form-item label="截止时间" required>
							<el-date-picker
							      v-model="addnewinfo.endtime"
							      type="datetime"
								  value-format="yyyy-MM-dd HH:mm:ss"
							      placeholder="选择日期时间">
							</el-date-picker>
						</el-form-item>
						
						<el-form-item label="接收人" required>
							<div style="display: flex;align-items: center;">
								<el-button type="primary" @click="select">选择</el-button>
								
							</div>
							<div>
								<span style="color: #999;">已选人员：</span>{{user_name}}
							</div>
						</el-form-item>

						<el-form-item label="上传附件" style="display: flex;align-items: center;">
							<el-upload
							  class="upload-demo"
							  :action="baseurl+'/api/login/upload'" :headers="{
							  		token:$store.state.userinfo.token
							  	}"
							  :on-success="handleAvatarSuccess"
							  :on-remove="handleChange"
							  :file-list="fileList">
							  <el-button size="small" type="primary">点击上传</el-button>
							</el-upload>
						</el-form-item>

					</el-form>

				</div>

				<div style="display: flex;align-content: center;margin-top: 30px;">
					<el-button @click="setaddnew">取 消</el-button>
					<el-button type="primary" @click="submitForm">确 定</el-button>
				</div>
			</div>
		</div>
		
		
		
		<selectuser ref="myselectuser" @selectedry="selectedry"></selectuser>
	</div>

</template>

<script>
	import {
		Message
	} from 'element-ui'
	import selectuser from '@/components/select_user.vue'
	export default {
		components: {
			selectuser
		},
		data() {
			return {
				value1:'',
				baseurl: "",
				listdata: [],
				addnewinfo: {
					name: '',
					content:'',
					endtime:''
				},
				addnewinfo_rules: {
					name: [{
						required: true,
						message: '请输入标题',
						trigger: 'blur'
					}],
					content: [{
						required: true,
						message: '请输入内容',
						trigger: 'blur'
					}]
				},
				imageUrl: '',
				id: '',
				user_id: '',
				user_name: '',
				fileList:[]
				
			}
		},
		created() {
			this.id = this.$route.query.id
			this.baseurl = this.$URL

		},
		methods: {
			select(){
				let cxidlist = this.user_id?this.user_id.split(','):[]
				let cxnamelist = this.user_name?this.user_name.split(','):[]
				let list = []
				if(cxidlist.length>0){
					cxidlist.forEach((item,index)=>{
						let obj={
							name:cxnamelist[index],
							id:Number(item)
						}
						list.push(obj)
					})
				}				
				this.$refs.myselectuser.selectuser = list.length>0?list:[]
				this.$refs.myselectuser.dialogVisible = true
			},
			selectedry(e){
				this.user_id = e.map((item)=>{ return item.id })
				this.user_id = this.user_id.join(',')
				this.user_name = e.map((item)=>{ return item.name })
				this.user_name = this.user_name.join(',')
			},
			onCreated(editor) {
				this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
			},
			getinfo() {
				this.$post({
					url: '/admin/banner/detail',
					params: {
						id: this.id
					}
				}).then((res) => {
					res.status = Number(res.status)
					res.action = Number(res.action)
					this.addnewinfo = res
					if (res.image) {
						this.imageUrl = this.$URL + res.image
					}
				})
			},
			submitForm() {				
				if (!this.addnewinfo.name) {
					this.$message.warning('请输入标题')
					return
				}
				if (!this.addnewinfo.content) {
					this.$message.warning('请输入内容')
					return
				}
				if (!this.addnewinfo.endtime) {
					this.$message.warning('请选择截止时间')
					return
				}
				if (!this.user_name) {
					this.$message.warning('请选择接收人员')
					return
				}
				this.addnewinfo.jsuser_ids = this.user_id
				if(this.fileList.length>0){
					let fj_ids = this.fileList.map(item=>{return item.id})
					this.addnewinfo.fujian = fj_ids.join(',')
				}
				this.$post({
					url: '/api/proposazj/add',
					params: this.addnewinfo
				}).then((res) => {
					this.$message.success('添加成功')
					this.$router.push('/system/zhengji_list')
				})
			},
			handleAvatarSuccess(res, file) {
				let obj ={
					name:res.file_name,
					url:res.fullurl,
					id:res.id
				}
				this.fileList.push(obj)
			},
			handleChange(file, fileList) {
				this.fileList = fileList			    
			},
			setaddnew() {
				this.$router.push('/system/zhengji_list')
			}
		}
	}
</script>

<style lang="scss" scoped>
	.box_main {
		background-color: #fff;
		padding: 15px 55px;
		width: 100%;
		box-sizing: border-box;
		min-height: 100%;
	}

	.search_box {
		display: flex;
		align-items: center;
		font-size: 0.16rem;

		.search_item {
			display: flex;
			align-items: center;
			margin-right: 0.5rem;

		}
	}

	.box_cont {
		margin-top: 20px;
	}

	.btn_list {
		display: flex;
		align-items: center;
	}

	.tc_title {
		font-size: 0.18rem;
		font-weight: bold;
		color: #333;
		position: relative;
		padding-left: 20px;
		margin-bottom: 20px;
	}

	.tc_title:after {
		content: '';
		position: absolute;
		height: 100%;
		width: 4px;
		left: 0px;
		background-color: rgb(26, 144, 253);
	}

	::v-deep td {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell>.cell {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell.is-leaf {
		background-color: rgb(242, 242, 242);
		color: #777;
	}

	::v-deep .el-table th.el-table__cell.is-leaf,
	.el-table td.el-table__cell {
		
	}

	::v-deep .el-table td.el-table__cell {
		
	}

	::v-deep .el-table {
		margin-top: 10px;
		border-left: none !important;
	}

	::v-deep .el-table__fixed {
		border-bottom: none;
	}

	::v-deep .el-table::before {
		background-color: #fff !important;
	}

	::v-deep .el-table__fixed::before {
		background-color: #fff !important;
	}

	::v-deep .el-dialog__header {
		display: flex !important;
	}

	::v-deep .el-dialog__footer span {
		display: flex !important;
		justify-content: space-around;
		width: 100%;
	}

	::v-deep .el-form-item__content {
		display: flex;
		flex-direction: column;
	}

	::v-deep .el-form-item__content {
		margin-left: 0 !important;
	}

	::v-deep .el-table__fixed-right::before {
		background-color: #fff !important;
	}

	.item_class {
		display: flex;
		align-items: center;
		width: 100%;
		box-sizing: border-box;

		.el-form-item {
			margin-right: 150px;
		}
	}

	::v-deep.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>